<!--
 * @Description: 加载中
 * @Date: 2019-12-23 12:29:18
 * @LastEditTime: 2020-04-11 11:09:34
 * @LastEditors: 前胡
 -->
<template>
  <div class="page-loading">
    <a-spin size="large" />
  </div>
</template>

<script>
export default {
  name: "loading",
  data() {
    return {};
  },
  mounted() {
    this.getGoPath();
  },
  methods: {
    // 页面跳转
    getGoPath() {
      if (this.$store.state.user.userinfo) {
        const route = this.$store.state.user.routes;
        const adminIndex = route.length - 2;
        const jumpName =
          route[adminIndex] &&
          route[adminIndex]["children"] &&
          route[adminIndex]["children"][0] &&
          route[adminIndex]["children"][0]["children"] &&
          route[adminIndex]["children"][0]["children"][0] &&
          route[adminIndex]["children"][0]["children"][0]['name'];
        if (jumpName) {
          this.$router.push({ name: jumpName });
        } else {
          const that = this;
          that.$message.error("用户未赋予任何页面权限");
          this.$store.commit("logout");
          window.location.href='/'
        }
      }
    }
  }
};
</script>

<style lang='less' scoped>
.page-loading {
  height: 40px;
  width: 40px;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
}
</style>